import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import Barcode from 'react-barcode';
import { getFirestore } from 'firebase/firestore';
import { deleteDoc, doc, addDoc, collection } from "firebase/firestore";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import initFirebase from './firebase/Init';
import getFavList from './core/GetFav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FavTab = () => {
  initFirebase();
  const db = getFirestore();
  const [height, setHeight] = useState(window.innerHeight);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [favData, setFavData] = useState([]);
  const [activeTab, setActiveTab] = useState('all');

  // Form states
  const [submitTitle, setSubmitTitle] = useState('');
  const [submitDescription, setSubmitDescription] = useState('');
  const [submitEve, setSubmitEve] = useState('');
  const [submitEan, setSubmitEan] = useState('');
  const [submitPrice, setSubmitPrice] = useState('');

  const germanLetters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'Ä', 'Ö', 'Ü', 'ß'
  ];

  const handleDone = (uid, item) => {
    confirmAlert({
      message: 'Möchtest du dieses Produkt von deinen Favoriten löschen?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => deleteDoc(doc(db, "fav", uid)).then(() => {
            setFavData(favData.filter(i => i !== item));
          })
        },
        {
          label: 'Nein',
          onClick: () => {}
        }
      ]
    });
  };

  const pushFavData = (result) => {
    const array = [];
    let i = 0;
    result.forEach((doc) => {
      array.push({
        docid: doc.id,
        pos: doc.data().pos,
        title: doc.data().title,
        short: doc.data().short,
        short2: doc.data().short2,
        price: doc.data().price
      });
      i++;
      if (i === result.size) {
        setFavData(array);
      }
    });
  };

  const handleCreate = () => {
    if (!submitDescription || !submitEan || !submitEve || !submitTitle || !submitPrice) {
      toast.error("Bitte fülle alle Felder aus!");
      return;
    }

    const isElementExists = favData.some(element => element.pos === submitEan);
    
    if (isElementExists) {
      toast.error(`Element mit der Position ${submitEan} existiert bereits.`);
    } else {
      setIsOpen(false);
      confirmAlert({
        message: 'Möchtest du dieses Produkt zu deinen Favoriten hinzufügen?',
        buttons: [
          {
            label: 'Ja',
            onClick: () => addDoc(collection(db, "fav"), {
              pos: submitEan,
              title: submitTitle,
              short: submitDescription,
              short2: submitEve,
              price: submitPrice
            }).then(() => {
              getFavList().then(pushFavData);
            })
          },
          {
            label: 'Nein',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const ProductCard = ({ item }) => {
    return (
      <div className="relative bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden h-full">
        <button 
          className="absolute top-4 right-4 z-10 p-1 rounded-full hover:bg-gray-100 transition-colors"
          onClick={() => handleDone(item.docid, item)}
        >
          <Star className="w-6 h-6 fill-yellow-400 text-yellow-400" />
        </button>
        
        <div className="p-6 flex flex-col h-full">
          <div className="flex-grow">
            <h3 className="text-xl font-semibold text-gray-900 mb-2 text-center">
              {item.title}
            </h3>
            <p className="text-gray-600 mb-4 text-center text-sm">
              {item.short}
            </p>
            
            <div className="flex items-center justify-center h-32 mb-4">
              <img
                src={`https://cdn1.interspar.at/cachableservlets/articleImage.dam/at/${item.pos}/dt_main.jpg`}
                alt={item.title}
                className="max-h-full max-w-full object-contain"
              />
            </div>
            
            <p className="text-sm text-gray-500 mb-4 text-center">
              {item.short2}
            </p>
          </div>
          
          <div className="flex justify-center mb-4">
            <Barcode 
              value={item.pos}
              width={1.2}
              height={50}
              fontSize={12}
              background="#FFFFFF"
            />
          </div>
          
          <div className="bg-[#df021a] text-white text-2xl font-bold text-center py-4 -mx-6 -mb-6">
            {item.price}€
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    
    getFavList().then((result) => {
      pushFavData(result);
      setIsLoading(false);
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filteredData = favData.filter(item => {
    if (activeTab === 'all') return true;
    return item.title.charAt(0).toLowerCase() === germanLetters[activeTab].toLowerCase() ||
           item.short.charAt(0).toLowerCase() === germanLetters[activeTab].toLowerCase();
  });

  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  return (
    <div className="w-full bg-gray-100 min-h-screen pb-24">
      {/* Letter Navigation */}
      <div 
        className="sticky top-0 bg-white shadow-sm z-50 overflow-x-auto cursor-grab active:cursor-grabbing scrollbar-hide"
        onMouseDown={(e) => {
          const ele = e.currentTarget;
          const pos = { left: ele.scrollLeft, x: e.clientX };

          const mouseMoveHandler = (e) => {
            const dx = e.clientX - pos.x;
            ele.scrollLeft = pos.left - dx;
          };

          const mouseUpHandler = () => {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
          };

          document.addEventListener('mousemove', mouseMoveHandler);
          document.addEventListener('mouseup', mouseUpHandler);
        }}
      >
        <div className="flex gap-1 p-2 select-none">
          <button
            onClick={() => setActiveTab('all')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'all' ? 'bg-gray-900 text-white' : 'hover:bg-gray-100'
            }`}
          >
            ALLE
          </button>
          {germanLetters.map((letter, index) => (
            <button
              key={letter}
              onClick={() => setActiveTab(index)}
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeTab === index ? 'bg-gray-900 text-white' : 'hover:bg-gray-100'
              }`}
            >
              {letter}
            </button>
          ))}
          <button
            onClick={() => {
              setIsOpen(true);
              setSubmitDescription("");
              setSubmitEan("");
              setSubmitEve("");
              setSubmitPrice("");
              setSubmitTitle("");
            }}
            className="px-4 py-2 rounded-lg bg-[#df021a] text-white"
          >
            +
          </button>
        </div>
      </div>

      {/* Product Grid */}
      <div className="p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((item, index) => (
            <ProductCard key={index} item={item} />
          ))}
        </div>
      </div>

      {/* Add Product Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <input
              type="text"
              placeholder="MARKE"
              value={submitTitle}
              onChange={e => setSubmitTitle(e.target.value)}
              className="w-full p-2 mb-4 border rounded text-center"
            />
            <input
              type="text"
              placeholder="BEZEICHNUNG"
              value={submitDescription}
              onChange={e => setSubmitDescription(e.target.value)}
              className="w-full p-2 mb-4 border rounded text-center"
            />
            <input
              type="text"
              placeholder="EVE"
              value={submitEve}
              onChange={e => setSubmitEve(e.target.value)}
              className="w-full p-2 mb-4 border rounded text-center"
            />
            <input
              type="text"
              placeholder="EAN"
              value={submitEan}
              onChange={e => setSubmitEan(e.target.value)}
              className="w-full p-2 mb-4 border rounded text-center"
            />
            <input
              type="text"
              placeholder="PREIS"
              value={submitPrice}
              onChange={e => setSubmitPrice(e.target.value)}
              className="w-full p-2 mb-4 border rounded text-center"
            />
            <div className="flex gap-2">
              <button
                onClick={() => setIsOpen(false)}
                className="flex-1 p-2 bg-gray-200 rounded"
              >
                ABBRECHEN
              </button>
              <button
                onClick={handleCreate}
                className="flex-1 p-2 bg-[#df021a] text-white rounded"
              >
                HINZUFÜGEN
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer position="bottom-center" theme="light" />
    </div>
  );
};

export default FavTab;