import { useState } from 'react';
import { Search, Star, Menu } from 'lucide-react';
import SearchTab from './Search';
import FavTab from './Fav';

const App = () => {
  const [activeTab, setActiveTab] = useState('search');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="h-screen w-full relative bg-gray-100">
      {/* Main Content */}
      <main className="">
        {activeTab === 'search' && <SearchTab />}
        {activeTab === 'favorites' && <FavTab />}
      </main>

      {/* Floating Navigation */}
      <div className="fixed bottom-6 right-6 flex flex-col items-end gap-4" style={{zIndex: 99}}>
        {/* Menu Items */}
        <div className={`flex flex-col gap-3 transition-all duration-300 ${isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'}`}>
          <button
            onClick={() => {
              setActiveTab('search');
              setIsMenuOpen(false);
            }}
            className={`h-14 w-14 rounded-full shadow-lg flex items-center justify-center transition-transform ${
              activeTab === 'search' 
                ? 'bg-[#df021a] scale-110' 
                : 'bg-white hover:scale-105'
            }`}
          >
            <Search className={`w-6 h-6 ${activeTab === 'search' ? 'text-white' : 'text-gray-600'}`} />
          </button>
          
          <button
            onClick={() => {
              setActiveTab('favorites');
              setIsMenuOpen(false);
            }}
            className={`h-14 w-14 rounded-full shadow-lg flex items-center justify-center transition-transform ${
              activeTab === 'favorites' 
                ? 'bg-[#df021a] scale-110' 
                : 'bg-white hover:scale-105'
            }`}
          >
            <Star className={`w-6 h-6 ${activeTab === 'favorites' ? 'text-white' : 'text-gray-600'}`} />
          </button>
        </div>

        {/* Main Menu Button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="h-16 w-16 rounded-full bg-[#df021a] shadow-lg flex items-center justify-center transition-transform hover:scale-105"
        >
          <Menu className="w-8 h-8 text-white" />
        </button>
      </div>
    </div>
  );
};

export default App;