import React, { useState, useEffect, useCallback } from 'react';
import { Search, Star } from 'lucide-react';
import Barcode from 'react-barcode';

const SearchTab = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [searchData, setSearchData] = useState({ hits: [], paging: {}, totalHits: 0 });
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchResults = useCallback(async (query, page) => {
    if (!query.trim()) return;
    
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.mydashboard.at/?search&q=${query}&p=${page}`);
      const data = await response.json();
      setSearchData(data);
      setSearchQuery(query);
      setCurrentPage(page);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchSearchResults(searchInput, 1);
  };

  const toggleFavorite = async (product) => {
    const isFavorite = favorites.some(fav => fav.pos === product.pos);
    
    if (isFavorite) {
      setFavorites(favorites.filter(fav => fav.pos !== product.pos));
    } else {
      const confirmed = window.confirm('Möchtest du dieses Produkt zu deinen Favoriten hinzufügen?');
      if (confirmed) {
        setFavorites([...favorites, product]);
      }
    }
  };

  const Pagination = () => {
    const { currentPage, pageCount } = searchData.paging || {};
    
    if (!pageCount || pageCount <= 1) return null;

    return (
      <div className="flex items-center justify-center gap-4 py-8 ">
        <button 
          className={`px-4 py-2 border-2 border-gray-200 rounded-lg transition-colors
            ${currentPage <= 1 ? 'bg-gray-100 cursor-not-allowed opacity-50' : 'bg-white hover:bg-gray-50 hover:border-gray-300'}`}
          disabled={currentPage <= 1}
          onClick={() => fetchSearchResults(searchQuery, currentPage - 1)}
        >
          ←
        </button>
        <span className="text-gray-600">
          {currentPage} / {pageCount}
        </span>
        <button 
          className={`px-4 py-2 border-2 border-gray-200 rounded-lg transition-colors
            ${currentPage >= pageCount ? 'bg-gray-100 cursor-not-allowed opacity-50' : 'bg-white hover:bg-gray-50 hover:border-gray-300'}`}
          disabled={currentPage >= pageCount}
          onClick={() => fetchSearchResults(searchQuery, currentPage + 1)}
        >
          →
        </button>
      </div>
    );
  };

  const ProductCard = ({ product }) => {
    const isFavorite = favorites.some(fav => fav.pos === product['masterValues']['product-number']);
    
    return (
      <div className="relative bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden h-full">
        <button 
          className="absolute top-4 right-4 z-10 p-1 rounded-full hover:bg-gray-100 transition-colors"
          onClick={() => toggleFavorite({
            pos: product['masterValues']['product-number'],
            title: product['masterValues']['title'],
            short: product['masterValues']['short-description-2'],
            short2: product['masterValues']['short-description-3'],
            price: product['masterValues']['regular-price'].toFixed(2)
          })}
        >
          <Star 
            className={`w-6 h-6 ${isFavorite ? 'fill-yellow-400 text-yellow-400' : 'text-gray-400'}`}
          />
        </button>
        
        <div className="p-6 flex flex-col h-full">
          <div className="flex-grow">
            <h3 className="text-xl font-semibold text-gray-900 mb-2 text-center">
              {product['masterValues']['title']}
            </h3>
            <p className="text-gray-600 mb-4 text-center text-sm">
              {product['masterValues']['short-description-2']}
            </p>
            
            <div className="flex items-center justify-center h-32 mb-4">
              <img
                src={product['masterValues']['image-url']}
                alt={product['masterValues']['title']}
                className="max-h-full max-w-full object-contain"
              />
            </div>
            
            <p className="text-sm text-gray-500 mb-4 text-center">
              {product['masterValues']['short-description-3']}
            </p>
          </div>
          
          <div className="flex justify-center mb-4">
            <Barcode 
              value={product['masterValues']['product-number']}
              width={1.2}
              height={50}
              fontSize={12}
              background="#FFFFFF"
            />
          </div>
          
          <div className="bg-[#df021a] text-white text-2xl font-bold text-center py-4 -mx-6 -mb-6">
            {product['masterValues']['regular-price'].toFixed(2)}€
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ minHeight: `${height}px` }} className="w-full bg-gray-100">
      <div className="sticky top-0 left-0 right-0 bg-gray-100 z-50 py-3">
        <form onSubmit={handleSearch} className="w-full px-3">
          <div className="flex gap-2 max-w-none">
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="SUCHE PRODUKT, STICHWORT, THEMA ..."
              className="flex-1 px-4 py-2 text-lg focus:outline-none rounded-lg border border-gray-200"
            />
            <button 
              type="submit" 
              className={`px-6 flex items-center justify-center rounded-lg transition-colors
                ${!searchInput.trim() || isLoading 
                  ? 'bg-gray-700 cursor-not-allowed' 
                  : 'bg-gray-900 hover:bg-gray-800'}`}
              disabled={!searchInput.trim() || isLoading}
            >
              <Search className="w-6 h-6 text-white" />
            </button>
          </div>
        </form>
      </div>

      <div className="mx-auto p-3" style={{ minHeight: `${height - 80}px` }}>
        {isLoading ? (
          <div className="flex justify-center items-center min-h-48 text-gray-500">
            Loading...
          </div>
        ) : searchData.totalHits > 0 ? (
          <div>
            {searchQuery && (
              <p className="text-center text-gray-500 my-1">
                {searchData.totalHits} Suchergebnisse für <strong>{searchQuery}</strong>
              </p>
            )}
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-3 ">
              {searchData.hits?.map((product, index) => (
                <ProductCard 
                  key={`${product['masterValues']['product-number']}-${index}`} 
                  product={product} 
                />
              ))}
            </div>
            
            <Pagination />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-16 text-gray-400">
            <Search className="w-16 h-16 mb-4" />
            <p>Keine Suchergebnisse gefunden</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchTab;